import logo from './logo.svg';
import './App.css';
import './css/general.css';
import './css/header.css';
import './css/home.css';
import Header from './components/Header';
import Home from './components/pages/Home'
import Vault from './components/pages/Vault'
import Affiliate from './components/pages/Affiliate'
import Gift from './components/pages/Gift'
import Loading from './components/errors/Loading'
import BadNetwork from './components/errors/BadNetwork'
import NoWeb3 from './components/errors/NoWeb3'
import { Renderer, Tokenizer } from 'marked'
import Web3 from 'web3';
import React, { Component, setState } from 'react';
import TOKEN from './build/contracts/XMAS.json';
import VaultContract from './build/contracts/Vault.json';


class App extends React.Component {


  state = {
    page:"home",
    address:0,
    account:0,
    isLoading:false,
    isBadNetwork:false,
    isWeb3:true,
  }

  popBox = {
    addValue:false,
    removeValue:false,
    valueUpdate:1
  }

  TOKEN = {
    balanceOf:0,
    balanceStack:0,
    instance:0,
    globalTax:0,
    affiliatorDiscountFee:0,
    TVLaffiliates:0,
    numberOfAffiliates:0,
    burnt:0,
    WWF:0,
    affiliator:false,
    refferalEntered:false
  }
  VaultContract = {
    info:0,
    tvl:0
  }

  externalLinks = {
    buyXMAS:"https://pancakeswap.finance/swap?outputCurrency=0x410461aB170571f72729e5Aee9Da9258604C1452"
  }

  instance = {
    TOKEN:0,
    VaultContract:0
  }

  

  forceUpdateHandler(){
    this.forceUpdate();
  };

  constructor (props){
    super(props)
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    this.lireweb3() 
    this.state.page = props.page

  }

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  async lireweb3(){
    this.state.isLoading = true;

    if (Web3.givenProvider != null){ // SI IL Y A DU WEB3

      const web3 = new Web3(Web3.givenProvider);
      await window.ethereum.enable();
      var StoredLastEthereumChain;

      const chainId = await web3.eth.getChainId();
      StoredLastEthereumChain = `0x${chainId.toString(16)}`; // save to localStorage

      web3.currentProvider.on(
        'chainChanged',
        chainId => {
          const lastChainId = StoredLastEthereumChain; // get from localStorage
          if (String(chainId) !== String(lastChainId)) {
            window.location.reload();
          }
        },
      );

      window.ethereum.on('accountsChanged', function (accounts) {
        window.location.reload();
      })

      const account= await web3.eth.getAccounts();
      this.state.account = account
      const networkid = await web3.eth.net.getId();
      if (networkid == 56  || networkid == 97  || networkid == 5777){ // IF le bon etwork

        this.instance.TOKEN = new web3.eth.Contract(TOKEN.abi, TOKEN.networks && TOKEN.networks[networkid].address)
        this.instance.VaultContract = new web3.eth.Contract(VaultContract.abi, VaultContract.networks && VaultContract.networks[networkid].address)
        this.instance.TOKEN.methods.balanceOf(this.state.account[0]).call()
        .then(result => this.TOKEN.balanceOf = result)
        .then(result => this.instance.VaultContract.methods.existingAddress(this.state.account[0]).call())
        .then(result => this.VaultContract.info = result)
        .then(result => this.instance.VaultContract.methods.TVL().call())
        .then(result => this.VaultContract.TVL = result)
        .then(result => this.instance.TOKEN.methods.refferalEntered(this.state.account[0]).call())
        .then(result => this.TOKEN.refferalEntered = result)
        .then(result => this.instance.TOKEN.methods.affiliatorDiscountFee().call())
        .then(result => this.TOKEN.affiliatorDiscountFee = result)
        .then(result => this.instance.TOKEN.methods.globalTax().call())
        .then(result => this.TOKEN.globalTax = result)
        .then(result => this.instance.TOKEN.methods.balanceOf("0x7e7BEA09C8031F86036dCF07de827b0D57349364").call())
        .then(result => this.TOKEN.WWF = result)
        .then(result => this.instance.TOKEN.methods.TVLaffiliates(this.state.account[0]).call())
        .then(result => this.TOKEN.TVLaffiliates = result)
        .then(result => this.instance.TOKEN.methods.numberOfAffiliates(this.state.account[0]).call())
        .then(result => this.TOKEN.numberOfAffiliates = result)
        .then(result => this.instance.TOKEN.methods.supplyBurnt().call())
        .then(result => this.TOKEN.burnt = result)
        .then(result => this.forceUpdateHandler)
        .then(result => {
          this.state.isLoading = false;
          this.setState({})
          })
          .then(result => this.instance.TOKEN.methods.balanceOf(this.state.account[0]).call())
          .then(result => console.log(this.TOKEN.balanceOf))
          

      }  // FIN DU IF NETWORK ID == BONNE BLOCKCHAIN
      else  {
        this.state.isLoading = false;
        this.state.isBadNetwork = true;
        this.setState({})
      }




    
    
  }  // FIN DU IF IL Y A DU WEB3
  else {
    this.state.isLoading = false;
    this.state.isWeb3 = false;
    this.setState({})
  }




  } // FIN DE LA FONCTION LIREWEB3()


  render(){
  return (
    <div className="App">
      <Header App={this}>{this}</Header> 
      {this.state.page == "home" ? <Home>{this}</Home> : <div></div>}
      {this.state.page == "vault" ? <Vault>{this}</Vault> : <div></div>}
      {this.state.page == "affiliate" ? <Affiliate>{this}</Affiliate> : <div></div>}
      {this.state.page == "gift" ? <Gift>{this}</Gift> : <div></div>}

      {this.state.isLoading ? <Loading /> : <div></div>}
      {this.state.isBadNetwork ? <BadNetwork /> : <div></div>}
      {this.state.isWeb3 ? <div></div> : <NoWeb3 />}
    </div>
  );
  }
}

export default App;
