import { Renderer } from 'marked'
import React from 'react'
import Gif from '../../img/load.gif'
import '../../css/load.css'

const Loading = () => {
    return(
        <div class="background">
        <img src={Gif}/><br />
        <p>Waiting for the blockchain ...<br />
        It may be long, BSC is currently congested ...
        </p>
        </div>
    )
}

export default Loading