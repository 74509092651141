import { Renderer } from 'marked'
import React from 'react'
import Menu from './header/Menu.js'
import SoldeCrypto from './header/SoldeCrypto.js'

const Header = ({children}) => {


    return (
        <header>
            <h1><a href="/">Crazy Santa</a></h1>
            <Menu>{children}</Menu>
            <SoldeCrypto>{children}</SoldeCrypto>



        </header>
    )
}

export default Header