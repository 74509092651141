import { Renderer } from 'marked'
import React from 'react'
import BoxSupp from './Vault/BoxSupp'
import '../../css/vault.css'

const Vault = ({children}) => {
    

    var addValue = false
    var removeValue = false

    console.log(children.instance.VaultContract.methods);
    return (
        <div class="content">
            <h2>Santa's Gift Vault</h2>
            <p>TVL : <span>{Math.floor(children.VaultContract.TVL/(10**18))} $XMAS</span></p>
            <p>Your locked value : <span>{Math.floor(children.VaultContract.info.Quantity/(10**18))} $XMAS</span></p>
            {children.VaultContract.info.Quantity > 0 ? <div></div> : <div></div>}

            {Math.floor(children.TOKEN.balanceOf/(10**18)) == 0 ? <span>

                {Math.floor(children.VaultContract.info.Quantity/(10**18)) == 0 ? <span>

                    <p>You do not have any $XMAS</p>
                    <a href={children.externalLinks.buyXMAS} target="_BLANK" class="oneButton">Buy some</a>
                </span> : <span>

                    <button class="oneButton" onClick={() => {children.popBox.removeValue = true;children.popBox.valueUpdate = 1;children.setState({})}}>REMOVE VALUE</button>

                    </span>}

               
               </span> : <span>
                   {Math.floor(children.VaultContract.info.Quantity/(10**18)) == 0 ? <span>

                    <button class="oneButton" onClick={() => {children.popBox.addValue = true;children.popBox.valueUpdate = 1;children.setState({})}}>ADD VALUE</button>


                   </span> : <span>

                    <button class="twoButtons" onClick={() => {children.popBox.addValue = true;children.popBox.valueUpdate = 1;children.setState({})}}>ADD VALUE</button>
                    <button class="twoButtons" onClick={() => {children.popBox.removeValue = true;children.popBox.valueUpdate = 1;children.setState({})}}>REMOVE VALUE</button>
                       
                       </span>}
                   
                   </span>}


           


           {children.popBox.addValue ? <BoxSupp name="Add">{children}</BoxSupp> : <div></div>}
           {children.popBox.removeValue ? <BoxSupp name="Remove">{children}</BoxSupp> : <div></div>}


        </div>
    )
}

export default Vault