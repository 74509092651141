import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter, BrowserRouter, Route, Routes, Switch } from 'react-router-dom'


const Root = () =>(
  <BrowserRouter>
    <Routes>
    <Route path='/' element={<App page="home" />} />
    <Route path='/home' element={<App page="home" />} />
    <Route path='/vault' element={<App page="vault" />} />
    <Route path='/affiliate' element={<App page="affiliate" />} />
    <Route path='/referal' element={<App page="affiliate" />} />
    <Route path='/gift' element={<App page="gift" />} />
    </Routes>
  </BrowserRouter>
)



ReactDOM.render(
  <Root />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
