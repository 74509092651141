import { Renderer } from 'marked'
import React from 'react'

const Gift = ({children}) => {
    return (
        <div class="content">
            <h2>Gift</h2>
            <p>All your gifts will be available on 25 decembre 2021 at 12PM GTM</p>
            <p>The different gift you can earn :</p>
            <ul>
                <li>$XMAS NFT wearable in Decentraland Metaverse for every holder of $XMAS</li>
                <li>A split of 10k USD in BNB with you affiliate points</li>
            </ul>

        </div>
    )
}

export default Gift