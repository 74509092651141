import { Renderer } from 'marked'
import React from 'react'
import {useLocation} from "react-router-dom";
import '../../css/buttons.css'

const Affiliate = ({children}) => {


      

    const search = useLocation().search;
    const address = new URLSearchParams(search).get('address');
    const affiliateLink = "http://" + window.location.hostname + "/referal?address=" + children.state.account[0];
    function setAffiliator(){
        if (children.popBox.valueUpdate != children.state.account[0]){
            setTimeout(function(){
                children.state.isLoading = true;
                children.setState({})
            }, 500)
            children.instance.TOKEN.methods.setAffiliator(children.popBox.valueUpdate).send({from:children.state.account[0]})
            .then(result => {
                children.TOKEN.refferalEntered = true;
                children.state.isLoading = false;
                children.setState({})
            });
        }   else    {
            alert("You cannot referal yourself.");
        }
    }
    

    return (
        <div class="content">
            <h2>Referal</h2>
            {children.TOKEN.refferalEntered ? <span>

                <p>You have entered your refferal address and now have {children.TOKEN.affiliatorDiscountFee}% reduction fees.</p>

                </span> : <span>
                
                <p>Enter the address of your referal :</p>
                <input type="text" id="affiliator" onChange={e=>{children.popBox.valueUpdate = e.currentTarget.value;children.setState({})}} value={address} /><br />
                <button class="oneButton" onClick={()=>{setAffiliator()}}>Confirm and reduce my fees by {children.TOKEN.affiliatorDiscountFee}%</button>
                </span>}
            


            <h3>Your affiliate link</h3>
            <p>Your referal link is : {affiliateLink}</p>
            <button class="oneButton" onClick={()=>{

                navigator.clipboard.writeText(affiliateLink)
                
                }}>Copy your referal link to your clipboard</button>
            
            <h3>Your affiliates</h3>
            <p>You have {children.TOKEN.numberOfAffiliates} {children.TOKEN.numberOfAffiliates > 1 ? <span>affiliates</span> : <span>affiliate</span>}</p>
            <p>The TVL of your affiliates is : {Math.floor(children.TOKEN.TVLaffiliates/(10**18))} $XMAS</p>


        </div>
    );
}

export default Affiliate