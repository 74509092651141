import { Renderer } from 'marked'
import React, { Children } from 'react'

const Menu = ({children}) => {
    return (
        <menu type="context" id="popup-menu">
            
            <a href="/">Home</a>
            <a href="/vault">Vault</a>
            <a href="/referal">Referal</a>
            <a href={children.externalLinks.buyXMAS} target="_BLANK">Buy $XMAS</a>



        </menu>
    )
}

export default Menu