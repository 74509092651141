import { Renderer } from 'marked'
import React from 'react'
import logo from '../../img/logo500x500.png'

const SoldeCrypto = ({children}) => {
    return (
        <div class="soldeCrypto">
            <p>{Math.floor(children.TOKEN.balanceOf/(10**18))}</p>
            <img src={logo} />



        </div>
    )
}

export default SoldeCrypto