import { Renderer } from 'marked'
import React from 'react'
import '../../css/load.css'

const NoWeb3 = () => {
    return(
        <div class="backgroundWhite">
        <div>
        <h1>Oops no Web3 Provider detected</h1><br />
        <div>You are not logged to a WEB3 provider, please log in and refresh this page</div><br />
        <div>A WEB3 provider is a web extension that link your browser with your Ethereum address, the most famous WEB3 provider is metamask</div><br />
        <div><a href="https://blog.wetrust.io/how-to-install-and-use-metamask-7210720ca047" target="_BLANK">If you want to install Metamask look at this tutorial</a></div>
        </div>
        </div>
    )
}

export default NoWeb3