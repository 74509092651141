import { Renderer } from 'marked'
import React from 'react'
import '../../css/load.css'

const BadNetwork = () => {
    return(
        <div class="backgroundWhite">
        <div>
        <h1>Wrong network</h1><br />
        <div>Please change the network to BSC network and refresh page</div><br />
        <div>$XMAS is only available on Binance Smart Chain network</div><br />
        <div><a href="https://academy.binance.com/fr/articles/connecting-metamask-to-binance-smart-chain" target="_BLANK">How to connect to BSC</a></div>
        </div>
        </div>
    )
}

export default BadNetwork