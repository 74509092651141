import { Renderer } from 'marked'
import React from 'react'
import Updates from '../home/Updates.js'

const Home = ({children}) => {
    return (
        <div class="content">
            <h2>Welcome</h2>


            <p>The cryptocurrency advent calendar</p>

            <div class="stat">
                <p><span>{Math.floor(children.VaultContract.TVL/(10**18))}</span>TVL of $XMAS</p>
            </div>
            <div class="stat">
                <p><span>{Math.floor(children.TOKEN.burnt/(10**18))}</span>$XMAS burnt</p>
            </div>
            <div class="stat">
                <p><span>{Math.floor(children.TOKEN.WWF/(10**18))}</span>$XMAS raised for WWF</p>
            </div>
            <div class="stat">
                <p><span>{Math.ceil((1640390400-Math.floor(Date.now()/1000))/86400)}</span>days until Xmas</p>
            </div>

            

        </div>
    )
}

export default Home