import { Renderer } from 'marked'
import React, {useState} from 'react'
import '../../../css/boxSupp/import.css'
import '../../../css/boxSupp/boxSupp.css'
import '../../../css/boxSupp/slider.css'
import croixGrise from '../../../img/cross.png';

const BoxSupp = ({children, name}) => {

    var lectureVar = 1;


    function correctLock(value){
        value = Math.round(value)
        if (value > children.TOKEN.balanceOf/(10**18))
        value = Math.floor(children.TOKEN.balanceOf/(10**18))
        if (isNaN(value))
        value = 0
        children.popBox.valueUpdate = value
        console.log(children.popBox.valueUpdate)
    }

    function correctWithdraw(value){
        value = Math.round(value)
        if (value > children.VaultContract.info.Quantity/(10**18))
        value = Math.floor(children.VaultContract.info.Quantity/(10**18))
        if (isNaN(value))
        value = 0
        children.popBox.valueUpdate = value
        console.log(children.popBox.valueUpdate)
    }

    console.log(children.TOKEN.balanceOf)

    if(name == "Add"){
        name = "Add XMAS"
        return(
            <div class="divBackgroundGrise">
                <div class="boxMiddle">
                    <img src={croixGrise} onClick={()=>{children.popBox.addValue = false;children.setState({})}} class="croix" />
                    <h1>{name}</h1>
                    <div class="rangeQuantite">
                    <input type="range" id="quantite" min="1" max={children.TOKEN.balanceOf/(10**18)} class="slider" onChange={e=>{children.popBox.valueUpdate = e.currentTarget.value;children.setState({})}} value={children.popBox.valueUpdate} />
                    </div>
                    <div class="quantiteAffichage">
                    <input type="text" value={children.popBox.valueUpdate} onChange={e=>{correctLock(e.currentTarget.value);children.setState({})}}/>
                    </div>
                    <button class="buttonAction" onClick={()=>{
                    setTimeout(function(){
                        children.state.isLoading = true;
                        children.setState({})
                    }, 500)
                    children.instance.VaultContract.methods.lockTOKEN(children.popBox.valueUpdate).send({from:children.state.account[0]})
                    .then(result => {
                        children.state.isLoading = false;
                        children.popBox.addValue = false;
                        children.TOKEN.balanceOf -= children.popBox.valueUpdate * (10**18);
                        if (children.TOKEN.refferalEntered)
                            children.VaultContract.info.Quantity = parseInt(children.VaultContract.info.Quantity) + ((parseInt(children.popBox.valueUpdate) * (10**18))* (100-(children.TOKEN.globalTax * (100-children.TOKEN.affiliatorDiscountFee)/100))/100);
                        else
                            children.VaultContract.info.Quantity = parseInt(children.VaultContract.info.Quantity) + ((parseInt(children.popBox.valueUpdate) * (10**18))* (100-children.TOKEN.globalTax)/100);


                        
                        
                        

                        
                        
                        children.setState({})
                        
                    })
                }}>{name}</button>
                </div>
    
            </div>
            
    
    
    
        )
        } // END OF ADD

        if(name == "Remove"){
            name = "Remove XMAS"
            return(
                <div class="divBackgroundGrise">
                    <div class="boxMiddle">
                        <img src={croixGrise} onClick={()=>{children.popBox.removeValue = false;children.setState({})}} class="croix" />
                        <h1>{name}</h1>
                        <div class="rangeQuantite">
                        <input type="range" id="quantite" min="1" max={children.VaultContract.info.Quantity/(10**18)} class="slider" onChange={e=>{children.popBox.valueUpdate = e.currentTarget.value;children.setState({})}} value={children.popBox.valueUpdate} />
                        </div>
                        <div class="quantiteAffichage">
                        <input type="text" value={children.popBox.valueUpdate} onChange={e=>{correctWithdraw(e.currentTarget.value);children.setState({})}}/>
                        </div>
                        <button class="buttonAction" onClick={()=>{
                        setTimeout(function(){
                            children.state.isLoading = true;
                            children.setState({})
                        }, 500)
                        children.instance.VaultContract.methods.withdrawTOKEN(children.popBox.valueUpdate).send({from:children.state.account[0]})
                        .then(result => {
                            children.state.isLoading = false;
                            children.popBox.removeValue = false;
                            
                            children.VaultContract.info.Quantity -= children.popBox.valueUpdate * (10**18);
                            if (children.TOKEN.refferalEntered)
                                children.TOKEN.balanceOf = parseInt(children.TOKEN.balanceOf) + ((parseInt(children.popBox.valueUpdate) * (10**18))* (100-(children.TOKEN.globalTax * (100-children.TOKEN.affiliatorDiscountFee)/100))/100);
                            else
                                children.TOKEN.balanceOf = parseInt(children.TOKEN.balanceOf) + ((parseInt(children.popBox.valueUpdate) * (10**18))* (100-children.TOKEN.globalTax)/100);



                            children.setState({})
                            
                        })
                    }}>{name}</button>
                    </div>
        
                </div>
                
        
        
        
            )
            } // END OF ADD

        
}

export default BoxSupp